
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n/index";
import {toolbarWidthFluid} from "@/core/helpers/config";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  setup() {
    const {t, te} = useI18n();

    const translate = (text) => {
      if (text == null) {
        return "";
      }
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      toolbarWidthFluid,
      translate,
    };
  },
});
