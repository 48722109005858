const DocMenuConfig = [
  {
    heading: "overview",
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "activities",
        route: "/activities",
        fontIcon: "bi-eye",
      },
      {
        heading: "employees",
        route: "/view-users",
        fontIcon: "bi-people",
      },
    ],
  },
  {
    heading: "dataCollection.collection",
    pages: [
      {
        heading: "dataCollection.times",
        route: "/collection/times",
        fontIcon: "bi-plus-circle",
      },
      {
        heading: "dataCollection.diets",
        route: "/collection/diets",
        fontIcon: "bi-plus-circle",
      },
    ],
  },
  {
    heading: "dataManagement.management",
    pages: [
      {
        heading: "dataManagement.evaluation",
        route: "/management/evaluation",
        fontIcon: "bi-list-ul",
      },
      {
        heading: "dataManagement.overtimes",
        route: "/management/overtimes",
        fontIcon: "bi-hourglass-bottom",
      },
      {
        heading: "dataManagement.times",
        route: "/management/times",
        fontIcon: "bi-clock",
      },
      {
        heading: "dataManagement.diets",
        route: "/management/diets",
        fontIcon: "bi-bag-check-fill",
      },
    ],
  },
  {
    heading: "account",
    route: "/account",
    pages: [
      {
        sectionTitle: "profile",
        route: "/profile",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "profileOverview",
            route: "/app/pages/profile/overview",
          },
          {
            heading: "projects",
            route: "/app/pages/profile/projects",
          },
          {
            heading: "documents",
            route: "/app/pages/profile/documents",
          },
          {
            heading: "activity",
            route: "/app/pages/profile/activity",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
